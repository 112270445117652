
.loadingContainer{
    height: 70vh;
}
.loadingContainer img{
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30vh;
}
